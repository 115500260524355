import './App.css';
import Component from './components/Component';
import Layout from './layout/Layout';

function App() {
    return (
        <Layout children={<Component />} />
    );
}

export default App;
