/**
 * v0 by Vercel.
 * @see https://v0.dev/t/a8l5h4QGXdm
 * Documentation: https://v0.dev/docs#integrating-generated-code-into-your-nextjs-app
 */
"use client"

import { useState } from "react"

export default function Component() {
    const [prompt, setPrompt] = useState("")
    const [loading, setLoading] = useState(null)
    const [generatedImage, setGeneratedImage] = useState(null)

    const generateImage = async () => {
        try {
            setGeneratedImage(null)
            const response = await fetch("https://starshipsss.mayureshchoudhary22.workers.dev?prompt=" + prompt, {
                method: "GET",
                headers: {
                    "Content-Type": "image/png"
                },
            })

            if (!response.ok) {
                throw new Error(response.status)
            }

            const blob = await response.blob()
            const url = URL.createObjectURL(blob)
            setGeneratedImage(url)

        } catch (error) {
            console.log(error.message);
            console.error("Error generating image:", error)
        } finally {
            setLoading(false)
        }
    }

    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-gray-50 dark:bg-gray-900 px-4 sm:px-6 lg:px-8">
            <div className="max-w-md w-full space-y-8">
                <div>
                    <h1 className="text-4xl font-bold text-gray-900 dark:text-gray-50">Starship Skin Generator</h1>
                    <p className="mt-2 text-gray-600 dark:text-gray-400">Enter a prompt to generate a unique starship skin.</p>
                </div>
                <div>
                    <form
                        onSubmit={(e) => {
                            e.preventDefault()
                            setLoading(true)
                            generateImage()
                        }}
                    >
                        <div className="flex items-center border-b border-gray-300 dark:border-gray-600 py-2">
                            <input
                                type="text"
                                placeholder="Enter your prompt..."
                                value={prompt}
                                onChange={(e) => setPrompt(e.target.value)}
                                className="appearance-none bg-transparent border-none w-full text-gray-900 dark:text-gray-50 mr-3 py-1 px-2 leading-tight focus:outline-none"
                            />
                            <button
                                type="submit"
                                className="flex-shrink-0 bg-blue-500 hover:bg-blue-700 border-blue-500 hover:border-blue-700 text-sm border-4 text-white py-1 px-2 rounded"
                            >
                                Generate
                            </button>
                        </div>
                    </form>
                </div>
                {generatedImage && (
                    <div className="mt-8">
                        <img
                            src={generatedImage}
                            alt="Generated Starship Skin"
                            width={600}
                            height={400}
                            className="max-w-full h-auto rounded-lg shadow-lg"
                        />
                    </div>
                )}
                {loading ? (
                    <div class="mt-8 animate-pulse">
                        <div class="h-48 w-full bg-gray-200 rounded-lg shadow-lg"></div>
                    </div>
                ): null}
            </div>
        </div>
    )
}