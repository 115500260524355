import './style.css'

export default function Layout({ children }) {
  return (
    <html lang="en">
      <body className='comfortaa-font'>
        {children}
      </body>
    </html>
  )
}